<template>
    <div class="passenger_box_two mt-2">
        <div class="passenger_body" style="background-image: url(/assets/img/passenger-box-banner.jpg)">
            <UserInformation
                v-for="(user, idx) in users"
                :key="idx"
                :index="idx"
                :user="user"
                :readOnly="readOnly"
                @updateUser="updateUserInformation"
            />
        </div>
    </div>

</template>

<script>
export default {
  components: {
    UserInformation: () => import('@/components/booking/atoms/UserInformation'),
  },
  props: {
    flight: {
      type: Object,
      default: null,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      users: [],
      readOnly: false,
    };
  },
  created() {
    const retry = window.localStorage.getItem('retryState'),
      dataBook = JSON.parse(window.localStorage.getItem('BookingDetailInfo')),
      BookingDetailInfo = dataBook.data,
      { sendingStage } = dataBook,
      { paxIds } = BookingDetailInfo.outwardFlight;
    // retry = {readOnly: true};
    if (retry !== 'null' && paxIds.length > 0 && sendingStage !== 'init') {
      const retryState = JSON.parse(retry);
      this.readOnly = retryState.readOnly;
      for (let i = 0; i < paxIds.length; i += 1) {
        const user = BookingDetailInfo.paxList[i];
        user.groupID = this.index;
        this.users.push(user);
      }
    } else {
      let idx = 1;
      for (let i = 0; i < this.flight.adult; i += 1) {
        this.users.push({
          groupID: this.index,
          id: idx,
          generation: 'adult',
          firstName: '',
          lastName: '',
          paxType: 'M',
          email: '',
          phone: '',
          birthDate: '',

        });
        idx += 1;
      }
      for (let i = 0; i < this.flight.child; i += 1) {
        this.users.push({
          groupID: this.index,
          id: idx,
          generation: 'child',
          firstName: '',
          lastName: '',
          paxType: 'M',
          email: '',
          phone: '',
          birthDate: '',
        });
        idx += 1;
      }
      for (let i = 0; i < this.flight.infant; i += 1) {
        this.users.push({
          groupID: this.index,
          id: idx,
          generation: 'infant',
          firstName: '',
          lastName: '',
          paxType: 'M',
          email: '',
          phone: '',
          birthDate: '',
        });
        idx += 1;
      }
    }
    this.users.forEach((user) => this.$emit('updatedUser', user));
  },
  methods: {
    updateUserInformation(pInfo) {
      const target = this.users.filter((user) => user.id === pInfo.id && user.groupID === pInfo.groupID)[0];
      Object.assign(target, pInfo);
      this.$emit('updatedUser', pInfo);
    },
  },
};
</script>

<style scoped>
    .passenger_header {
        padding: 20px 20px;
        background: rgb(2,0,36);
        background: linear-gradient(180deg, rgba(2,0,36,1) 0%, rgba(1,98,172,1) 0%, rgba(38,135,209,1) 100%);
    }

    .passenger_body {
        padding: 20px 20px;
        /*min-height: 334px;*/
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        position: relative;
    }

    .passenger_body:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgb(11 102 172 / 95%);
    }

    @media (max-width: 479px){
        .passenger_header {
            background : #fff;
            padding : 5px 15px
        }
        .guest_details_body h4 {
            color: rgb(33,37,41);
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 10px;
        }
        .passenger_body {
            padding : 0px 15px;
        }

        .passenger_body:before{
            background:#fff;
        }
    }

</style>
